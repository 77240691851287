.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 1rem;
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.section__paddinga {
    padding: 1rem 6rem 2rem 6rem;
  }

  .p__cormoranta {
    font-family: var(--font-base);
    color: var(--color-blue);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: none;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 63px;
    padding-bottom: 2rem;
  }
  
  .app__headerz-h1 {
    font-family: var(--font-base);
    color: var(--color-blue);
    letter-spacing: 0.02em;
    line-height: 50px;
    font-size: 45px;
}


  .headtext__cormorantx {
    font-family: var(--font-base);
    color: var(--color-blue);
    font-size: 40px;
    line-height: 33.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }

  .p__opensanx {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 35px;
    font-size: 26px;
  }


.app__wrapperzi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-greey);
  
    min-height: 60vh;
  }
.app__chef-content_quote img {
    width: 47px;
    height: 47px;
    margin: 0 1rem 0rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
}

@media screen and (max-width: 1150px) {
    
  .app__header h1 {
      letter-spacing: 0.02em;
      line-height: 1em;
      font-size: 3em;
      width: 95%;
  }  

  .app__header p{
      letter-spacing: 0.006em;
      line-height: 1.2em;
      font-size: 1.8em;
      width: 70%;
  }
}

@media screen and (max-width: 650px) {

  .app__header h1 {
    letter-spacing: 0.02em;
    line-height: 1.18em;
    font-size: 1.9em;
    width: 95%;
}  

.app__header p{
    letter-spacing: 0.006em;
    line-height: 1.2em;
    font-size: 1.3em;
    width: 90%;
}
.app__header button{
  letter-spacing: 0.006em;
  line-height: 1.2em;
  font-size: 0.9em;
  width: auto;
}

}

@media screen and (max-width: 460px) {
  .app__header h1 {
    letter-spacing: 0.02em;
    line-height: 1.18em;
    font-size: 1.8em;
    width: 95%;
}  

.app__header p{
    letter-spacing: 0.006em;
    line-height: 1.2em;
    font-size: 1.3em;
    width: 90%;
}

.app__header button{
  letter-spacing: 0.006em;
  line-height: 1.2em;
  font-size: 0.9em;
  width: auto;
}
}

@media screen and (max-width: 440px) {

.app__header h1 {
  letter-spacing: 0.02em;
  line-height: 1.18em;
  font-size: 1.6em;
  width: 95%;
}  

.app__header p{
    letter-spacing: 0.006em;
    line-height: 1.2em;
    font-size: 1.3em;
    width: 90%;
}

.app__header button{
  letter-spacing: 0.006em;
  line-height: 1.2em;
  font-size: 0.9em;
  width: auto;
}
}

@media screen and (max-width: 420px) {

  .app__header h1 {
    letter-spacing: 0.02em;
    line-height: 1.18em;
    font-size: 1.6em;
    width: 95%;
  }  
  
  .app__header p{
      letter-spacing: 0.006em;
      line-height: 1.2em;
      font-size: 1.3em;
      width: 90%;
  }
  
  .app__header button{
    letter-spacing: 0.006em;
    line-height: 1.2em;
    font-size: 0.9em;
    width: auto;
  }
  
  }

  @media screen and (max-width: 360px) {
    
    .section__paddi {
      padding: 0rem 0rem 0rem 0rem;
    }
    .app__header h1 {
      letter-spacing: 0.02em;
      line-height: 1.1em;
      font-size: 1.29em;
      width: 95%;
    }  
    
    .app__header p{
        letter-spacing: 0.006em;
        line-height: 1.2em;
        font-size: 0.99em;
        padding: auto;
        width: 90%;
    }
    
    .app__header button{
      letter-spacing: 0.006em;
      line-height: 1.2em;
      font-size: 0.8em;
      width: auto;
    }
    
    }

*{
    margin: 0em;
    padding: 0em;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  
  body .cardex-container{
    background-color: var(--color-crimson);
  }

  .cardex-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2em;
    width: 100%;
  
  }
  
  .cardex {
    width: 100em;
    background-color: var(--color-crimson);
    border-radius: 10px;
    overflow: hidden;
    margin: 4em;
  }