.app__header {
    background-color: var(--color-crimson);

}

.app__wrappery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  
  /* background: var(--color-crimson); */

  min-height:0vh;
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-blue);
    letter-spacing: 0.02em;
    line-height: 60px;
    text-transform: none;
    font-size: 5em;
    padding-bottom: 1.5em;
    width: 90%;
}

.p__opensansf {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 400;
    letter-spacing: 0.02em;
    text-transform:none;
    line-height: 30px;
    font-size: 26px;
    width: 80%;
  }

.app__wrapper_infoz {
    padding-top: 6rem;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: top;
    align-items: flex-start;
    flex-direction: column;
  }

.app__header-img img {
    width: 90%;
}

.app__wrapper_img img {
    border-radius: 5px;
    margin-top: 50px;
}

*{
  margin: 0em;
  padding: 0em;
  box-sizing: border-box;
  font-family: sans-serif;
}


body .carda-container{
  /* background-color: var(--color-crimson); */
}

.carda-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2em;
  width: 100%;

}

.carda {
  width: 100em;
  border-radius: 10px;
  overflow: hidden;
  margin: 4em;
}

.carda img {
  width: 100%;
  height: auto;
}

.carda-content {
  padding: 3em;
  align-content: center;

}

.cardx-content h3 {
  font-size: 1.8em;
  margin: 1em 2em 1em 2em;
  color: var(--color-blue);

}

.cardi-content p {
  color: var(--color-blue);
  font-size: 1.83em;
  margin: 1em 1em 1em 1.0em;
  line-height: 1.2em;
}

.cardi-content .btn {
  display: inline-block;
  padding: 1em  2em;
  background-color: #333;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 2em;
  color: white;
}

@media screen and (max-width: 1150px) {
    
    .app__header h1 {
        letter-spacing: 0.02em;
        line-height: 1em;
        font-size: 3em;
        width: 95%;
    }  

    .app__header p{
        letter-spacing: 0.006em;
        line-height: 1.2em;
        font-size: 1.8em;
        width: 70%;
    }
}

@media screen and (max-width: 650px) {
  .app__header h1 {
    letter-spacing: 0.02em;
    line-height: 1.9em;
    font-size: 2em;
    width: 95%;
}  

.app__header p{
    letter-spacing: 0.006em;
    line-height: 1.2em;
    font-size: 0.8em;
    width: 70%;
}
    
}

@media screen and (max-width: 300px) {
   
    .app__header{
        padding: 0;
        margin-right: 0em;
    }
    .section__paddi {
      padding: 1rem 0rem 1rem 0rem;
    }
    
    .app__headers-h1 {
        letter-spacing: 0.02em;
        line-height: 1.5em;
        font-size: 1.4em;
        width: 100%;
    }  

    .p__opensansf{
        letter-spacing: 0.006em;
        line-height: 1.1em;
        font-size: 1.2em;
        width: 100%;
    }
}

@media screen and (max-width: 200px) {
    .app__header{
        padding: 0;
        margin-right: 0em;
    }
    
    .app__headers-h1 {
        letter-spacing: 0.02em;
        line-height: 1.5em;
        font-size: 1.2em;
        width: 95%;
    }  

    .p__opensansf{
        letter-spacing: 0.006em;
        line-height: 1.1em;
        font-size: 1.0em;
        width: 90%;
    }
}



