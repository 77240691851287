.app__specialMenu {
    flex-direction: column;
    background: var(--color-greey);
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__headi-h1 {
    font-family: var(--font-base);
    color: var(--color-blue);
    letter-spacing: 0.01em;
    line-height: 53px;
    font-size: 48px;
}

.p__opensansx {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 300;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 16px;
  }

  .p__opensansa {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 300;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 21px;
  }

  .p__opensansz {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 300;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 22px;
  }

.headtext__cormorantd {
    font-family: var(--font-base);
    color: var(--color-blue);
    font-size: 25px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }
  
.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 30px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}

/* *******************************************
.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
    flex: 1;
    margin: 30px;
    width: 60%;
    height: 450px;
    flex-direction: column;   
    background: var(--color-bluey);
    border-radius: 0px;
    padding: 10px 40px; 
}******************************************** */

/* .app__specialMenu-menu_cocktails:hover { 
    border: none;
    background: var(--color-white);
    border-radius: 10px; 
}

.app__specialMenu-menu_wine:hover { 
    border: none;
    background: var(--color-white);
} */

.app__specialMenu-menu_img {
    width: 350px;
    margin: 0 2rem;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: 500px;
    border-radius: 5px;
}

.app__specialMenu-menu_items {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    width: 100%;
}

@media screen and (max-width: 2000px) {
   .app__specialMenu-menu_img {
    width: 350px;
   }

   .app__specialMenu-menu_img img {
    height: 600px;
   }
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }
    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}