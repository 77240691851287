.app__gallery {
    display: flex;
    flex-direction: row;
    background: var(--color-greey);
    padding: 4rem 0 4rem 6rem;


}

.p__cormorante {
  font-family: var(--font-base);
  color: var(--color-blue);
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 5.9px;
  font-size: 35px;
}

.app__gal{
  background: var(--color-white);
  line-height: 250px;
  padding: 2rem 3rem 0rem 0rem;
}


.app__gall{
  display: flex;
  flex-direction: row;
  background: var(--color-white);
  padding: 0rem 0rem 0rem 0rem;
  line-height: 40px;
  flex-wrap: nowrap;
  width: 100%;
}



.flexCentery {
    display: flex;
    flex: 1;
    row-gap: 4rem;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
    padding:2em  4em;
    margin-top: 0em;
  }

  .flex__centerz {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .headText__cormorant {
    font-family: var(--font-base);
    font-size: 6em;
    flex: content;
    text-align: center;
    color: var(--color-golden);
    /* background: var(--color-bluey); */
    line-height: 100px;
    /* padding: 10px 80px 2px 80px; */
  }

  .headtexta__cormorant {
    font-family: var(--font-base);
    color: var(--color-blue);
    font-size: 0.6em;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: none;
  }

  .p__ope {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 600;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 14px;
    font-size: 30px;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .p__opensans {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 20px;
    font-size: 0.1em;
  }
  
  .flexColCenter {
    display: flex;
    flex: 1;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background: var(--color-crimson);
    padding: 1em 8em;
    margin: 2.6em;
    align-self: normal;
    /* border: 0px solid var(--color-grey);
    box-shadow: 1px 1px 4px var(--color-gray); */
    border-radius: 15px;
  }
  .flexColStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .flexColEnd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

.stats {
  width: 75%;
  /* justify-content: space-between; */
}

.stati>:nth-child(1) {
    font-size: 1rem;
    color: blue;
}


.stati>:nth-child(1)>:last-child {
    color: blue;
}
.secondaryText {
    /* color: rgb(140, 139, 139); */
    font-family: var(--font-alt);
    color: var(--color-black);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 10px;
    padding-bottom: 3px;
}

.app__navbar-lin {
  flex: 2;
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  justify-content: flex-start;
  align-items: center;

  list-style: none;
}

.app__navbar-lin li {
  margin: 1rem 1rem;
  cursor: pointer;
}

.app__navbar-lin li:hover {
  color: var(--color-grey);
      
}

/* @media screen and (max-width: 1090px) {
  .app__gal{
    background: var(--color-white);
    line-height: 250px;
    padding: 2rem 1rem 0rem 0rem;
    width: 100%;
  }

  .app__gall{
    display: flex;
    flex-direction: row;
    background: var(--color-white);
    padding: 0rem 0rem 0rem 0rem;
    line-height: 40px;
    flex-wrap: nowrap;
    width: 100%;
  }  
  
}
@media screen and (max-width: 948px) {
  .headtext__cormorant {
    font-family: var(--font-base);
    font-size: 2em;
    flex: content;
    text-align: center;
    line-height: 100px;
  }
  .app__gal{
    background: var(--color-white);
    line-height: 250px;
    padding: 2rem 1rem 0rem 0rem;
    width: 100%;
  }

  .app__gall{
    display: flex;
    flex-direction: row;
    background: var(--color-white);
    line-height: 40px;
    flex-wrap: nowrap;
    width: 100%;
  }  
  .flexColCenter {
    display: flex;
    flex: 1;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background: var(--color-crimson);
    padding: 2.3em;
    margin: 2.1em;
    border-radius: 15px;
    width: 80%;
  }
  .flexCentery {
    display: flex;
    flex: 1;
    row-gap: 0.0em;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding:-0.9em  1em;
    margin-top: -2.5em;
  }
  
} */

*{
  margin: 0em;
  padding: 0em;
  box-sizing: border-box;
  font-family: sans-serif;
}

body{
  background-color: white;
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2em;

}

.card {
  width: 25em;
  background-color: var(--color-crimson);
  border-radius: 10px;
  overflow: hidden;
  margin: 2em;
  height: 17em;
}

.card img {
  width: 100%;
  height: auto;
}

.card-content {
  padding: 3em;
  align-content: center;

}

.card-content h3 {
  font-size: 2.0em;
  margin: 1em 2em 1em 2em;
  color: var(--color-blue);

}

.card-content p {
  color: var(--color-blue);
  font-size: 1.83em;
  margin: 1em 1em 1em 2em;
  line-height: 1.2em;
  justify-content: center;
}

.card-content .btn {
  display: inline-block;
  padding: 1em  2em;
  background-color: #333;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 2em;
  color: white;
}


