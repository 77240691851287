.app__chef-contenti {
    display: flex;
    flex-direction: row;
    width: 100%;

    margin-top: 1rem;
    margin-bottom: 4rem;
}

.section__paddings {
    padding: 0rem 4rem;
  }

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.headtext__cormorantiv {
  font-family: var(--font-base);
  color: var(--color-blue);
  font-size: 2.4em;
  line-height: 0em;
  width: 14em;
  height: 17.29em;
  letter-spacing: 0.04em;
  display: flex;
  justify-content: flex-start;
}

.p__opensv {
    
    font-family: var(--font-alt);
    color: var(--color-blue);
    padding-top: 0em;
    padding-bottom: 0.3em;
    font-weight: 550;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 1.1em;
    font-size: 2.2em;
  }

.p__opensansv {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 300;
    letter-spacing: 0.04em;
    margin: 0rem;
    width: 20.7em;
    text-transform:none;
    line-height: 1.4em;
    font-size: 1.7em;
  }




.app__wrapperzi {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 6vh;
  }

.app__chef-content_quote img {
    width: 47px;
    height: 47px;
    margin: 0 1rem 0rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}
.app__wrapper_inz {
    padding-top: 0rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;

  }


  .headtext__cormoranti {
    font-family: var(--font-base);
    color: var(--color-blue);
    font-size: 20px;
    line-height: 0px;
    letter-spacing: 0.04em;
    display: flex;
    justify-content: flex-start;
  }

  .app__wrapper_img {
    flex: 1;
    width: 20em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
  }

  .app__wrapper_imge img {
    width: 350px;
    height: 250px;
    border: 0px solid var(--color-gray);
    border-radius: 3%;
  }

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
}

.container{
  display: flex;
  justify-content: center;
  
  
}

.contains{
  border-bottom: 0px solid black;
  display: flex;
  justify-content: center;
  width: 100%;
  
}

.flex-item{
  color: white;
  padding: 0rem;
  text-align: center;
}

.item-1{
  padding: 0em;
  text-align: center;
  
}

.item-2{
  padding: 0em;
  text-align: left;
  
}

.item-3{
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .contains{
    display: flex;
    flex-direction: row;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: 15em;
    margin-bottom: -3em;
    margin-top: -1em;
    overflow: hidden;
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: -1.4em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 912px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: 3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: 4.4em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 825px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: 3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: 4.4em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 769px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: 3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: 4.4em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 710px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 60em;
    margin-left: 2.5em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 650px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -9em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 50em;
    margin-left: 0.59em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 589px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -9em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 45em;
    margin-left: 0.59em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 544px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -9em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 45em;
    margin-left: 0.59em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 531px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -8em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }

  .twoz p{
    
    margin-left: 1em;
    padding-left: 1em;
    padding-right: 9em;
    
  }
    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 45em;
    margin-left: 0.041em;
    padding: 0.4em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 496px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -7em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }

    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 45em;
    margin-left: 0.1em;
    padding-right: 4em;
    font-size: 1.030em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 464px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -6em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }

    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 45em;
    margin-left: 0.1em;
    padding-right: 7em;
    padding-left: 1em;
    font-size: 1.030em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 424px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -4.3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }

    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 40em;
    margin-left: 0.3em;
    padding-right: 7em;
    padding-left: 1em;
    font-size: 1.030em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 390px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -4.3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }

    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 40em;
    margin-left: 0.3em;
    padding-right: 9em;
    padding-left: 1em;
    font-size: 1.030em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 360px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -4.3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }

    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 40em;
    margin-left: 0.8em;
    padding-right: 9em;
    padding-left: 1em;
    font-size: 1.030em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 345px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -3.3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    font-size: .8em;
  }

    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 38em;
    margin-left: 0.8em;
    padding-right: 10em;
    padding-left: 1em;
    font-size: 1.030em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 325px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -2.3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    font-size: .8em;
  }

    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 40em;
    margin-left: 1.8em;
    padding-right: 3.5em;
    padding-left: 1em;
    font-size: 0.88em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

@media screen and (max-width: 300px) {
  .contains{
    display: flex;
    flex-direction: column;
  }
  .onez {
    display: flex;
    flex-direction: column;
    margin-left: -2.3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    font-size: .8em;
  }

    
  .twoz div{
    display: flex;
    flex-direction: column;
    width: 40em;
    margin-left: 1.8em;
    padding-right: 3.5em;
    padding-left: 1em;
    font-size: 0.88em;
    
  }
  .app__wrapper_imge p{
    width: 60em;
    padding: auto;
  }
}

