.app__chef-contenti {
    display: flex;
    flex-direction: row;
    width: 100%;

    margin-top: 1rem;
    margin-bottom: 4rem;
}

.section__paddings {
  padding: 0rem 4rem;
  
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.section__padding {
  padding: 0rem 6rem 0rem 6rem;
  flex: content;
  flex-wrap: wrap;
}

.p__opensz {
    font-family: var(--font-alt);
    color: var(--color-blue);
    padding-top: 0.1em;
    padding-bottom: 0.3em;
    font-weight: 550;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 1.1em;
    font-size: 2.2em;
    width: 13em;
  }

  .p__opensansz {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 300;
    letter-spacing: 0.05em;
    width: 30rem;
    
    line-height: 1.2em;
    font-size: 1.2em;
  }

  .p__cormoranta {
    font-family: var(--font-base);
    color: var(--color-blue);
    font-weight: 500;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 4em;
  }
  

  .app__wrapperzi {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 6vh;
  }


.app__wrapperzie {
    padding-left: 4rem;
    /* border-bottom: 1px solid var(--color-black); */
  }

.app__chef-content_quote img {
    width: 47px;
    height: 47px;
    margin: 0 1rem 0rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}
.app__wrapper_in {
    padding-top: 0rem;
    display: flex;
    justify-content:flex-start;
    width: 100%;
    /* width: 60%; */
    /* justify-content: top; */
    /* align-items: flex-start; */
    flex-direction: column;
    /* background-color: blue; */

  }

  .app__wrapper_infom {
    padding-top: 2rem;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: top;
    align-items: flex-start;
    flex-direction: column;
    line-height: normal;
  }

  .section__paddingz {
    padding: 0rem 6rem 0rem 6rem;
  }


  .headtext__cormorantis {
    font-family: var(--font-base);
    color: var(--color-blue);
    line-height: 0px;
    letter-spacing: 0.01em;
    height: 0em;
    margin: 1.5em;
    /* display: flex;
    justify-content: flex-start; */
    /* text-transform: capitalize; */
  }

  .app__wrapper_imge img {
    width: 350px;
    height: 250px;
    border: 0px solid var(--color-gray);
    border-radius: 3%;
  }

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
}

.container{
  /* border: 4px solid black; */
  display: flex;
  justify-content: center;
  
  
  
}

.contain{
  border-bottom: 1px solid black;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  /* margin: 0em 27em; */
  /* background-color: #6B0803; */
  /* width: 100%; */
  /* width: 100em; */
  
}



.flex-item{
  color: white;
  /* font-size: 1.5rem; */
  padding: 2rem;
  text-align: center;
}

.item-1{
  /* background-color: #B4Bf35; */
  padding: 7em;
  text-align: center;
  
}

.item-2{
  /* background-color: #B95F21; */
  padding: 3em;
  text-align: left;
  
}

.item-3{
  /* background-color: #1C4C56; */
  /* border-bottom: 1px solid #000; */
  display: flex;
  justify-content: center;
}



@media screen and (max-width: 1024px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: row;
    margin-left: 9em;
    margin-bottom: -8.1em;
    margin-top: -4em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: 0.98em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: 29em;
    padding: 0.4em;
    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

}


@media screen and (max-width: 912px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: 0em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: 0.98em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: 19em;
    padding: 0.4em;
    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

}

@media screen and (max-width: 825px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: 0em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: 0.98em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: 19em;
    padding: 0.4em;
    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

}

@media screen and (max-width: 769px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: 0em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: 0.98em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: 19em;
    padding: 0.4em;
    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

}

@media screen and (max-width: 710px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one{
    display: flex;
    flex-direction: column;
    margin-left: -9em;
    width: 90em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: 0.98em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 60em;
    margin-left: 26em;
    padding: 0.4em;
   
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
  }

}

@media screen and (max-width: 650px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -9.5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: 0.98em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 50em;
    margin-left: 26em;
    padding: 0.4em;
   
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
  }

}

@media screen and (max-width: 589px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -9em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: 0.98em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 50em;
    margin-left: 26em;
    padding: 0.4em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
  }

}

@media screen and (max-width: 544px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -9em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .88em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 50em;
    margin-left: 30em;
    padding: 0.4em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
  }

}

@media screen and (max-width: 531px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -7em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .88em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 50em;
    margin-left: 32em;
    padding: 0.4em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
  }

}

@media screen and (max-width: 496px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -6.5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .86em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 38em;
    margin-left: 34em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
    font-size: 1.13em;
  }

}

@media screen and (max-width: 478px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -5.8em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .81em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 38em;

    margin-left: 37em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
    font-size: 1.13em;
  }

}

@media screen and (max-width: 444px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -3.8em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .72em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 34.5em;
    margin-left: 45em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: -0.3em;
    padding: auto;
    font-size: 1.19em;
  }

}

@media screen and (max-width: 406px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -2.4em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .60em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width: 34em;
    margin-left: 57em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
    font-size: 1.19em;
  }

}

@media screen and (max-width: 372px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -1.5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .60em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width:38em;
    margin-left: 58em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 0.2em;
    padding: auto;
    font-size: 1.19em;
  }

}

@media screen and (max-width: 370px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -1.5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .60em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width:38em;
    margin-left: 58em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 0.2em;
    padding: auto;
    font-size: 1.19em;
  }

}

@media screen and (max-width: 370px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -1.5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .60em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width:38em;
    margin-left: 58em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 0.2em;
    padding: auto;
    font-size: 1.19em;
  }

}

@media screen and (max-width: 345px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -1.5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .60em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width:34em;
    margin-left: 58em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 0.2em;
    padding: auto;
    font-size: 1.19em;
  }

}

@media screen and (max-width: 335px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: -0.5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .60em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width:32em;
    margin-left: 59em;
    padding: 0.2em;    
  }
  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 0.2em;
    padding: auto;
    font-size: 1.19em;
  }

}

@media screen and (max-width: 320px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: 1.1em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .50em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width:32em;
    margin-left: 75.2em;
    padding: 0.1em;    
  }
  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 0.2em;
    padding: auto;
    font-size: 1.19em;
  }

}

@media screen and (max-width: 300px) {
  .contain{
    display: flex;
    flex-direction: column;
  }
  .one {
    display: flex;
    flex-direction: column;
    margin-left: 1.1em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
    
  }
  .two {
    display: flex;
    flex-direction: column;
    font-size: .50em;
    
  }
  
  .two div{
    display: flex;
    flex-direction: column;
    width:32em;
    margin-left: 75.2em;
    padding: 0.1em;    
  }
  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 0.2em;
    padding: auto;
    font-size: 1.19em;
  }

}










