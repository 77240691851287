.app__gallery {
    display: flex;
    flex-direction: row;
    background: var(--color-greey);
    padding: 2rem 0 4rem 2rem;


}

.p__cormorante {
  font-family: var(--font-base);
  color: var(--color-blue);
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 5.9px;
  font-size: 35px;
}

.app__gala{
  background: var(--color-white);
  line-height: 25px;
  padding: 2rem 0 4rem 2rem;
}


.app__galla{
  display: flex;
  flex-direction: row;
  /* background: var(--color-crimson); */
  padding: 2rem 0 5rem 6rem;
  line-height: 15px;
}

.app__wrapper_imgz {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin-left: 2rem;
  }

  .app__wrapper_imgz img {
    width: 150px;
}



.flexCenterd {
    display: flex;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: 0px;
    width: 60%;
    height: 21em;
  }
  .flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .headTexti__cormorant {
    font-family: var(--font-base);
    font-size: 70px;
    flex: content;
    text-align: center;
    color: var(--color-golden);
    /* background: var(--color-bluey); */
    line-height: 110px;
    padding: 30px 80px 2px 80px;
  }

  .p__ope {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 600;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 14px;
    font-size: 30px;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .p__opensans {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 16px;
  }
  
  .flexColCenterz {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: var(--color-crimson); */
    padding: 5px;
    /* border: 0px solid var(--color-grey);
    box-shadow: 1px 1px 4px var(--color-gray); */
    border-radius: 4%;
  }
  .flexColStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .flexColEnd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

.stats {
    width: 75%;
    justify-content: space-between;
}

.statsz {
  width: 85%;
  justify-content: space-between;
}

.stat>:nth-child(1) {
    font-size: 2.5rem;
    color: green;
}

.stat>:nth-child(1)>:last-child {
    color: green;
}
.secondaryText {
    /* color: rgb(140, 139, 139); */
    font-size: 0.9rem;
    font-family: var(--font-alt);
    color: var(--color-gray);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 16px;
    padding-bottom: 3px;
}

.app__navbar-lin {
  flex: 2;
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  justify-content: flex-start;
  align-items: center;

  list-style: none;
}

.app__navbar-lin li {
  margin: 1rem 1rem;
  cursor: pointer;
}

.app__navbar-lin li:hover {
  color: var(--color-grey);
      
}
