.app__gallery {
    display: flex;
    flex-direction: row;
    background: var(--color-greey);
    padding: 2rem 0 4rem 2rem;


}
.app__wrapper_infozx {
  padding-top: 0rem;
  flex: 1;
  display: flex;
  justify-content: top;
  align-items: flex-start;
  flex-direction: column;
}

.app__headery {
  background-color: var(--color-white);
}

.p__cormorante {
  font-family: var(--font-base);
  color: var(--color-blue);
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 5.9px;
  font-size: 35px;
  width: 24em;
  margin-right: 3em;
}

.app__gala{
  background: var(--color-white);
  line-height: 25px;
  padding: 1rem 0 1rem 2rem;
  align-self: normal;
}


.app__galla{
  display: flex;
  flex-direction: row;
  /* background: var(--color-crimson); */
  padding: 2rem 0 5rem 6rem;
  line-height: 25px;

}

.app__wrapper_imgz {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin-left: 2rem;
  }

  .app__wrapper_imgz img {
    width: 150px;
}



.flexCenterd {
    display: flex;
    row-gap: 2em;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: 5px;
  }
  .flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .headTexti__cormorant {
    font-family: var(--font-base);
    font-size: 70px;
    flex: content;
    text-align: center;
    color: var(--color-golden);
    /* background: var(--color-bluey); */
    line-height: 110px;
    padding: 30px 80px 2px 80px;
  }

  .p__ope {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 600;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 14px;
    font-size: 30px;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .p__ope a:hover {
    color: var(--color-grey);
        
  }

  .p__opensans {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 16px;
  }
  
  .flexColCenterz {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: var(--color-crimson); */
    padding: 3px;
    /* border: 0px solid var(--color-grey);
    box-shadow: 1px 1px 4px var(--color-gray); */
    border-radius: 4%;
  }
  .flexColStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .flexColEnd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

.stats {
    width: 75%;
    justify-content: space-between;
}

.statsz {
  width: 95%;
  justify-content: space-between;
}

.stat>:nth-child(1) {
    font-size: 2.5rem;
    color: green;
}

.stat>:nth-child(1)>:last-child {
    color: green;
}
.secondaryText {
    /* color: rgb(140, 139, 139); */
    font-size: 0.9rem;
    font-family: var(--font-alt);
    color: var(--color-gray);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 16px;
    padding-bottom: 3px;
}

.app__navbar-lin {
  flex: 2;
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  justify-content: flex-start;
  align-items: center;

  list-style: none;
}

.app__navbar-lin li {
  margin: 1rem 1rem;
  cursor: pointer;
}

.app__navbar-lin li:hover {
  color: var(--color-grey);
      
}

/* @media screen and (max-width: 1090px) {
  .app__gal{
    background: var(--color-white);
    line-height: 250px;
    padding: 2rem 1rem 0rem 0rem;
    width: 100%;
  }

  .app__gall{
    display: flex;
    flex-direction: row;
    background: var(--color-white);
    padding: 0rem 0rem 0rem 0rem;
    line-height: 40px;
    flex-wrap: nowrap;
    width: 100%;
  }  
  
}
@media screen and (max-width: 948px) {
  .headtext__cormorant {
    font-family: var(--font-base);
    font-size: 2em;
    flex: content;
    text-align: center;
    line-height: 100px;
  }
  .app__gal{
    background: var(--color-white);
    line-height: 250px;
    padding: 2rem 1rem 0rem 0rem;
    width: 100%;
  }

  .app__galla{
    display: flex;
    flex-direction: row;
    background: var(--color-white);
    line-height: 40px;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 26em;
  }  
  .flexColCenter {
    display: flex;
    flex: 1;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background: var(--color-crimson);
    padding: 2.3em;
    margin: 2.1em;
    border-radius: 15px;
    width: 80%;
  }
  .flexCentery {
    display: flex;
    flex: 1;
    row-gap: 0.0em;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding:-0.9em  1em;
    margin-top: -2.5em;
  }
  
} */

*{
  margin: 0em;
  padding: 0em;
  box-sizing: border-box;
  font-family: sans-serif;
}

body{
  background-color: white;
}

.cardi-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2em;

}

.cardi {
  width: 25em;
  background-color: var(--color-crimson);
  border-radius: 10px;
  overflow: hidden;
  margin: 3em;
}

.cardi img {
  width: 100%;
  height: auto;
}

.cardi-content {
  padding: 1em;
  align-content: center;

}

.cardi-content h3 {
  font-size: 1.8em;
  margin: 1em 2em 1em 2em;
  color: var(--color-blue);

}

.cardi-content p {
  color: var(--color-blue);
  font-size: 1.83em;
  margin: 1em 1em 1em 1.0em;
  line-height: 1.2em;
}

.cardi-content .btn {
  display: inline-block;
  padding: 1em  2em;
  background-color: #333;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 2em;
  color: white;
}
