@import-normalize;

.app__chef-contenti {
    display: flex;
    flex-direction: row;
    width: 100%;

    margin-top: 1rem;
    margin-bottom: 2rem;
}

.section__paddings {
    padding: 2rem 4rem;
  }

  
.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.p__opensc {
    font-family: var(--font-alt);
    color: var(--color-blue);
    padding-top: 0.1em;
    padding-bottom: 0.3em;
    font-weight: 550;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 1.1em;
  }

  .p__opensansd {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 300;
    letter-spacing: 0.04em;
    margin: 0rem;
    width: 19.9em;
    text-transform:none;
    /* border: 1px solid #000;
    padding: 2rem; */
    line-height: 28px;
    font-size: 1.72em;
    
  }

  .app__wrapper_infox {
    padding-top: 6rem;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: top;
    align-items: flex-start;
    flex-direction: column;
    line-height: normal;
  }



.app__wrapperzi {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 6vh;
  }

.app__chef-content_quote img {
    width: 47px;
    height: 47px;
    margin: 0 1rem 0rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}
.app__wrapper_in {
    padding-top: 0rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    /* justify-content: top; */
    /* align-items: flex-start; */
    flex-direction: column;

  }

  .headtext__cormorantid {
    font-family: var(--font-base);
    color: var(--color-blue);
    font-size: 2.4em;
    line-height: 0em;
    width: 14em;
    height: 22em;
    letter-spacing: 0.04em;
    display: flex;
    justify-content: flex-start;
    /* text-transform: capitalize; */
  }
  .app__wrapper_imge img {

    width: 35em;
    height: 25em;
    border: 0px solid var(--color-gray);
    border-radius: 3%;
  }

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
}

.container{
  /* border: 4px solid black; */
  display: flex;
  justify-content: center;
  width: max-content;
  
  
}

.containz{
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  margin: 0em 27em;
  /* background-color: #6B0803; */
  width: 100%;

  
}

.flex-item{
  color: white;
  /* font-size: 1.5rem; */
  padding: 2rem;
  text-align: center;
}

.item-1{
  /* background-color: #B4Bf35; */
  padding: 0em;
  text-align: center;
  
}

.item-2{
  /* background-color: #B95F21; */
  padding: 0em;
  text-align: left;
  
}

.item-3{
  /* background-color: #1C4C56; */
  /* border-bottom: 1px solid #000; */
  display: flex;
  justify-content: center;
}



@media screen and (max-width: 1024px) {
  .containz{
    display: flex;
    flex-direction: row;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -29.2em;
    margin-bottom: -3em;
    margin-top: -1.1em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    margin-left: 16em;
    padding-left: 6em;
    flex-wrap: wrap;
    font-size: 0.9em;
    width: 40em;
    
  }
  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: -1.em;
    padding-left: 1.7em;
    padding-right: 2em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 95em;
    margin-left: -4em;
    padding: 1em;
  }

  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

  .twos img {
    margin-left: 5em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 912px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -7.9em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    margin-left: 19em;
    padding-left: 6em;
    flex-wrap: wrap;
    font-size: 0.9em;
    width: 72em;
    
  }
  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: -1.em;
    padding-left: 1.7em;
    padding-right: 2em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 95em;
    margin-left: -4em;
    padding: 1em;
  }

  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

  .twos img {
    margin-left: 5em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 825px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -7.9em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    margin-left: 19em;
    padding-left: 6em;
    flex-wrap: wrap;
    font-size: 0.9em;
    width: 72em;
    
  }
  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: -1.em;
    padding-left: 1.7em;
    padding-right: 2em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 95em;
    margin-left: -4em;
    padding: 1em;
  }

  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

  .twos img {
    margin-left: 5em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 769px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -7.9em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    margin-left: 19em;
    padding-left: 6em;
    flex-wrap: wrap;
    font-size: 0.9em;
    width: 72em;
    
  }
  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: -1.em;
    padding-left: 1.7em;
    padding-right: 2em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 95em;
    margin-left: -4em;
    padding: 1em;
  }

  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

  .twos img {
    margin-left: 5em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 710px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -2em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }

  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: 3.1em;
    padding-left: 1.7em;
    padding-right: 2em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 80em;
    margin-left: -1em;
    padding: 1em;
  }

  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
  }

  .twos img {
    margin-left: 10em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 650px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -2em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  

  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: 3.1em;
    padding-left: 1.7em;
    padding-right: 2em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 65em;
    margin-left: -1em;
    padding: 1em;
  }

  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 1.6em;
  }

  .twos img {
    margin-left: 8em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 589px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -2em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  

  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: 3.1em;
    padding-left: 1.7em;
    padding-right: 2em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 60em;
    margin-left: 1em;
    padding: 1em;
  }

  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 1.6em;
  }

  .twos img {
    margin-left: 8em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 544px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -1em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  

  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: 3.6em;
    padding-left: 1.7em;
    padding-right: 5em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 60em;
    margin-left: 1em;
    padding: 1.2em;
  }

  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 1.6em;
  }

  .twos img {
    margin-left: 8em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 531px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: 0.15em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    font-size: .88em;
    width: auto;
    
  }
  

  .twos p{
    display: flex;
    flex-direction: column;
    margin-left: 4.8em;
    padding-left: 1.7em;
    padding-right: 9em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 60em;
    margin-left: 1em;
    padding: 1.2em;
  }

  .app__chef-contenti div {
    display:flex;
    flex-direction: column;
    margin-left: 1.6em;
  }

  .twos img {
    margin-left: 10em;
    width: 40em;
    padding-top: 0.8em;
  }
}

@media screen and (max-width: 496px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: 1.2em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    font-size: .86em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 48em;
    margin-left: 3em;
    padding: 0.4em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
    font-size: 1.13em;
  }

}

@media screen and (max-width: 464px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones{
    display: flex;
    flex-direction: column;
    margin-left: -1.22em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }

  
  .twos {
    display: flex;
    flex-direction: column;
    font-size: .796em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 48em;
    margin-left: 3em;
    padding: 0.4em;    
  }
  .app__chef-contenti div {
    display:flex;
    margin-left: 0.6em;
    padding: auto;
    font-size: 1.13em;
  }

}

@media screen and (max-width: 444px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -1em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    font-size: .9em;
    padding: auto;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 30em;
    margin-left: -2em;
    padding: 0.2em;    
  }
  .doe p {
    display:flex;
    margin-left: 5.4em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 9.9em;
    padding: 1rem;
    font-size: 2.19em;
  }

}

@media screen and (max-width: 423px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -4em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    font-size: .7em;
    padding: auto;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 30em;
    margin-left: -2em;
    padding: 0.2em;    
  }
  .doe p {
    display:flex;
    margin-left: 4em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 8.9em;
    padding: 1rem;
    font-size: 2.19em;
  }

}


@media screen and (max-width: 406px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -3em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  .twos {
    display: flex;
    flex-direction: column;
    font-size: .7em;
    padding: auto;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 35em;
    margin-left: -5.5em;
    padding: 0.2em;    
  }
  .doe p {
    display:flex;
    margin-left: 4em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 8.9em;
    padding: 1rem;
    font-size: 2.19em;
  }

}

@media screen and (max-width: 390px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  
  .twos p{
    font-size: .7em;
    margin-left: 3em;
    padding-right: 4em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 30em;
    margin-left: -7.5em;
    padding: 0.2em;    
  }


  .doe p {
    display:flex;
    margin-left: 6em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 8.9em;
    padding: 1rem;
    font-size: 2.19em;
  }

}

@media screen and (max-width: 360px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: -5em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  
  .twos p{
    font-size: .7em;
    margin-left: 3em;
    padding-right: 5em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 28em;
    margin-left: -5.5em;
    padding: 0.2em;    
  }
/* 
  .doe img {
    width: 2em;
    margin-left: 2em;
  } */

  .doe p {
    display:flex;
    margin-left: 7em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 10.9em;
    margin-right: 5em;
    padding: 1rem;
    font-size: 2.19em;
  }

}

@media screen and (max-width: 335px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    font-size: 0.7em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  
  .twos p{
    font-size: .7em;
    margin-left: 4.7em;
    padding-right: 10em;
    
  }

  .p__opensz p{
    padding: 2em;
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 25em;
    margin-left: -3.8em;
    padding: 0.2em;    
  }

  .doe p {
    display:flex;
    margin-left: 7em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 10.9em;
    margin-right: 5em;
    padding: 1rem;
    font-size: 2.19em;
  }

}

@media screen and (max-width: 325px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: 3em;
    font-size: 0.7em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  
  .twos p{
    font-size: .7em;
    margin-left: 5em;
    padding-right: 7em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 35em;
    margin-left: 1.9em;
    font-size: 0.7em;
    padding: 0.1em;    
  }

  .doe p {
    display:flex;
    margin-left: 7em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 10.9em;
    margin-right: 5em;
    padding: 1rem;
    font-size: 2.19em;
  }

}

@media screen and (max-width: 300px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    font-size: 0.7em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  
  .twos p{
    font-size: .7em;
    margin-left: 4.5em;
    padding-right: 7em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 35em;
    margin-left: 1.9em;
    padding: 0.1em;    
  }

  .doe p {
    display:flex;
    margin-left: 7em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 10.9em;
    margin-right: 5em;
    padding: 1rem;
    font-size: 2.19em;
  }

}

@media screen and (max-width: 295px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: 2.5em;
    font-size: 0.7em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  
  .twos p{
    font-size: .7em;
    margin-left: 4.9em;
    padding-right: 7em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 35em;
    margin-left: 3.9em;
    padding: 0.1em;    
  }

  .doe p {
    display:flex;
    margin-left: 7em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 10.9em;
    margin-right: 5em;
    padding: 1rem;
    font-size: 2.19em;
  }

}

@media screen and (max-width: 270px) {
  .containz{
    display: flex;
    flex-direction: column;
  }
  .ones {
    display: flex;
    flex-direction: column;
    margin-left: 5.8em;
    font-size: 0.7em;
    margin-bottom: -3em;
    margin-top: -5em;
    overflow: hidden;
  }
  
  .twos p{
    font-size: 1em;
    margin-left: 6.8em;
    
    padding-right: 8em;
    
  }
  
  .twos div{
    display: flex;
    flex-direction: column;
    width: 28em;
    margin-right: 2em;
    margin-left: 7.3em;
    padding: 0.1em;    
  }

  .doe p {
    display:flex;
    margin-left: 7em;
    padding: 0rem;
    font-size: 3.19em;
  }

  .doe div {
    display:flex;
    margin-left: 10.9em;
    margin-right: 5em;
    padding: 1rem;
    font-size: 2.19em;
  }

}
