.app__gallery {
    display: flex;
    flex-direction: row;
    background: var(--color-greey);
    padding: 2rem 0 4rem 2rem;


}

.p__cormorante {
  font-family: var(--font-base);
  color: var(--color-blue);
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 5.9px;
  font-size: 35px;
}

.app__gala{
  background: var(--color-white);
  line-height: 25px;
  padding: 2rem 0 4rem 2rem;
}


.app__galla{
  display: flex;
  flex-direction: row;
  /* background: var(--color-crimson); */
  padding: 2rem 0 5rem 6rem;
  line-height: 25px;
}

.app__wrapper_imgz {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  
    margin-left: 0rem;
  }

  .app__wrapper_imgz img {
    width: 150px;
}



.flexCenterd {
    display: flex;
    row-gap: 2em;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: 1px;
    width: 100%;
  }
  .flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .headTexti__cormorant {
    font-family: var(--font-base);
    font-size: 70px;
    flex: content;
    text-align: left;
    color: var(--color-golden);
    /* background: var(--color-bluey); */
    line-height: 110px;
    padding: 30px 80px 2px 80px;
  }

  .p__opel {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 700;
    letter-spacing: 0.06em;
    text-transform:none;
    line-height: 1.26em;
    font-size: 0.27em;
    padding: 0;
  }

  .p__opex {
    margin-right: 0em;
    font-size: 0.78em;
    font-family: var(--font-alt);
    padding: 0.9em;
    width: 15em;
    margin-left: 0.5em;
  }


  .p__opensans {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 16px;
  }
  
  .flexColCenterzi {
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    flex-direction: column;
    background: var(--color-crimson);
    padding:1.5em;
    width: 30%;
    /* border: 0px solid var(--color-grey);
    box-shadow: 1px 1px 4px var(--color-gray); */
    border-radius: 8%;
  }

  .p__opel a:hover {
    color: var(--color-grey);
        
  }

  .flexColStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .flexColEnd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

.stats {
    width: 95%;
    justify-content: space-between;
}

.statsz {
  width: 95%;
  justify-content: space-between;
}

.stat>:nth-child(1) {
    font-size: 2.5rem;
    color: var(--color-black);
}

.stat>:nth-child(1)>:last-child {
    color: var(--color-black);
}
.secondaryText {
    /* color: rgb(140, 139, 139); */
    font-size: 0.9rem;
    font-family: var(--font-alt);
    color: var(--color-gray);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 16px;
    padding-bottom: 3px;
}

.app__navbar-lin {
  flex: 2;
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  justify-content: flex-start;
  align-items: center;

  list-style: none;
}

.app__navbar-lin li {
  margin: 1rem 1rem;
  cursor: pointer;
}

.app__navbar-lin li:hover {
  color: var(--color-grey);     
}



@media screen and (max-width: 1334px) {
    .p__cormorante {
      margin-right: 30em;
      letter-spacing: 0.05em;
      line-height: 0.9em;
      font-size: 0.42em;
      width: 19%;
  } 

    .p__opel p {
      font-family: var(--font-alt);
      color: var(--color-blue);
      font-weight: 700;
      letter-spacing: 0.06em;
      text-transform:none;
      line-height: 1.2em;
      font-size: 0.99em;
  }
  .p__opel {
    width: 40em;
    letter-spacing: 0.06em;
  }
  .p__opex{
    width: 49em;
    font-size: 0.97em;
  }
}

@media screen and (max-width: 1180px) {
  .p__opex{
    width: 49em;
    font-size: 0.97em;
  }

}
@media screen and (max-width: 1110px) {
  
  .p__opex{
    width: 59em;
    font-size: 0.93em;
  }

}

@media screen and (max-width: 1070px) {
  
  .p__opex{
    width: 59em;
    font-size: 0.90em;
  }

}

@media screen and (max-width: 1040px) {

  .p__opel {
    width: 49em;
    letter-spacing: 0.06em;
    line-height: 1.2em;
    font-size: 0.21em;
  }
  
  .p__opex{
    width: 40em;
    font-size: 0.80em;
  }

  .p__cormorante {
    margin-right: 70em;
    letter-spacing: 0.05em;
    line-height: 0.9em;
    font-size: 0.42em;
    width: 29%;
} 

}


@media screen and (max-width: 940px) {
  .p__opel {
    width: 49em;
    letter-spacing: 0.06em;
    line-height: 1.2em;
    font-size: 0.21em;
  }
  
  .p__opex{
    width: 90%;
    font-size: 0.70em;
  }

}

@media screen and (max-width: 845px) {

  .flexColCenterzi{
    display:flex;
    flex-direction: column;
    flex:1;
    width: 80%;
    height:20vh;
    border-radius: 15px;
    padding: auto;
  }
  .p__opex{
    font-size: 0.82em;
    padding: 0.09em;
    margin-left: 0.6em;
  }
  .p__opel {
    width: 99em;
    letter-spacing: 0.06em;
    line-height: 1.20em;
    font-size: 0.17em;
  }
  .p__cormorante{
    width: 90%;
    align-items: center;
    align-content: center;
  }

}

@media screen and (max-width: 350px) {

  .flexColCenterzi{
    display:flex;
    flex-direction: column;
    flex:1;
    width: 80%;
    height:18vh;
    border-radius: 15px;
    padding: auto;
  }
  .p__opex{
    font-size: 0.62em;
    padding: 0.09em;
    margin-left: 0.6em;
  }
  .p__opel {
    width: 99em;
    letter-spacing: 0.06em;
    line-height: 1.20em;
    font-size: 0.13em;
  }
  .p__cormorante{
    width: 100%;
    align-items: center;
    align-content: center;
  }

}

*{
  margin: 0em;
  padding: 0em;
  box-sizing: border-box;
  font-family: sans-serif;
}

body{
  background-color: white;
}

.cardx-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.5em;

}

.cardx {
  width: 25em;
  background-color: var(--color-crimson);
  border-radius: 10px;
  overflow: hidden;
  margin: 2em;
}

.cardx-content {
  padding: 2em;
  align-content: center;

}

.cardx-content h3 {
  font-size: 1.8em;
  margin: 1em 2em 1em 2em;
  color: var(--color-blue);

}

.cardx-content p {
  color: var(--color-blue);
  font-size: 1.83em;
  margin: 1em 1em 1em 1.0em;
  line-height: 1.2em;
}

.cardx-content .btn {
  display: inline-block;
  padding: 1em  2em;
  background-color: #333;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 2em;
  color: white;
}
