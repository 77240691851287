.app__aboutus {
    position: relative;
    background: var(--color-crimson);

}

.section__paddingx {
    padding: 0rem 6rem 0rem 6rem;
    display: flex;
    flex-direction: column;
  }

  .app__wrapper_infoa {
    padding-top: 0rem;
    flex: 1;
    width: 100em;
    display: flex;
    justify-content: top;
    align-items: flex-start;
    flex-direction: column;
    line-height: normal;
  }

.app__aboutus-overlay {
    position: absolute;
    inset: 0;

}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__chef-contentiz {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
    /* border: 1px solid var(--color-grey); */
    border-radius: 10px;
    /* box-shadow: 1px 1px 4px var(--color-grey); */
    background: var(--color-crimson);
    padding: 10px 40px; 
}

.section__paddingz {
    padding: 1rem 6rem 1rem 6rem;
}  

.app__def{
    background: var(--color-crimson);
}

.app__wrapper_imge a:hover {
    color: var(--color-grey);
        
}

.p__opensansx {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 300;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 26px;
  }

  .p__ope {
    color: var(--color-blue);
    font-weight: 300;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 16px;
  }

.app__specialMenuz {
    flex-direction: column;
    background: var(--color-crimson);
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
    flex: 1;
    margin: 2em;
    width: 100em;
    height: 40em;
    flex-direction: column;   
    background: #e9c0cb;
    border-radius: 25px;
    padding: 8em 1em; 
}


.app__specialMenu-menu_cocktails:hover { 
    border: none;
    background: #EED1D3;
}

.app__specialMenu-menu_wine:hover { 
    border: none;
    background: var(--color-white);
    /* border-radius: 10px; */
    /* box-shadow: 1px 1px 4px var(--color-gray); */
}

.app__specialMenu-menu_img {
    width: 350px;
    margin: 0 2rem;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: 500px;
    border-radius: 5px;
}

.app__specialMenu-menu_items {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    width: 100%;
}

*{
    margin: 0em;
    padding: 0em;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  
  body .cardiae-container{
    background-color: var(--color-crimson);
  }

  .cardiae-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2em;
    width: 100%;
  
  }
  
  .cardiae {
    width: 100em;
    background-color: var(--color-crimson);
    border-radius: 10px;
    overflow: hidden;
    margin: 4em;
  }
  
  .cardiae img {
    width: 100%;
    height: auto;
  }
  
  .cardiae-content {
    padding: 3em;
    align-content: center;
  
  }
  
  .cardiae-content h3 {
    font-size: 1.8em;
    margin: 1em 2em 1em 2em;
    color: var(--color-blue);
  
  }
  
  .cardiae-content p {
    color: var(--color-blue);
    font-size: 1.83em;
    margin: 1em 1em 1em 1.0em;
    line-height: 1.2em;
  }
  
  .cardiae-content .btn {
    display: inline-block;
    padding: 1em  2em;
    background-color: #333;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 2em;
    color: white;
  }

 @media screen and (max-width: 769px) {
   .blog h1{
      width: 90%;
      font-size: 5em;
      padding-bottom: 0.5em;
   }

   .blog p{
    width: 90%;
    padding-bottom: 2.5em;
   }

   .blog button {
    margin-bottom: 0em;
   }
   .cardiae-container{
    margin-bottom: -8em;
   }

   .fine{
    padding-bottom: 3em;
   }

   .inst h1 {
    font-size: 3em;
   }

   .inst {
    font-size: 0.7em;
    width: 8em;
   }

   .stay p div{
    background-color: #EED1D3;
   }
   .p__opensans {
    padding-right: 5em;
   }

   .was{
    padding-right: 0em;
    margin-left: 6em;
   }
   
   .app__chef-contentiz {
    display: flexbox;
    flex-direction: column;
    margin-left: 35em;
    

   }
   .app__chef-contentiz p{
    margin-left: 3em;
    padding: 0em 1em 0 -1em;
   }

   .app__specialMenu-menu_cocktails{
    width: 100%;
   }
}

@media screen and (max-width: 709px) {
  .blog h1{
     width: 90%;
     font-size: 5em;
     padding-bottom: 0.5em;
  }

  .blog p{
   width: 90%;
   padding-bottom: 2.5em;
  }

  .blog button {
   margin-bottom: 0em;
  }
  .cardiae-container{
   margin-bottom: -8em;
  }

  .fine{
   padding-bottom: 3em;
  }

  .inst h1 {
   font-size: 3em;
  }

  .inst {
   font-size: 0.7em;
   width: 8em;
  }

  .stay p div{
   background-color: #EED1D3;
  }
  .p__opensans {
   padding-right: 5em;
  }

  .was{
   padding-right: 0em;
   margin-left: 6em;
  }
  
  .app__chef-contentiz {
   display: flexbox;
   flex-direction: column;
   margin-left: 42em;
   padding-right: 10em;
   margin-right: 7em;
   

  }
  .app__chef-contentiz p{
   padding: 0em 1em 0 -1em;
   margin-right: 3em;
  }

  .app__specialMenu-menu_cocktails{
   width: 100%;
  }
}

@media screen and (max-width: 690px) {
  .blog h1{
     width: 90%;
     font-size: 5em;
     padding-bottom: 0.5em;
  }

  .blog p{
   width: 90%;
   padding-bottom: 2.5em;
  }

  .blog button {
   margin-bottom: 0em;
  }
  .cardiae-container{
   margin-bottom: -8em;
  }

  .fine{
   padding-bottom: 3em;
  }

  .inst h1 {
   font-size: 3em;
  }

  .inst {
   font-size: 0.7em;
   width: 8em;
  }
  
  .app__chef-contentiz p{
   margin-left: 2em;
   font-size: 0.9em;
   line-height: 0.09em;
  }

  .app__chef-contentiz div{
    padding: 0 10em 0 5em;

   /* padding: 0em 1em 0 -1em; */
  }
}

@media screen and (max-width: 630px) {
  .blog h1{
     width: 90%;
     font-size: 5em;
     padding-bottom: 0.5em;
  }

  .blog p{
   width: 90%;
   padding-bottom: 2.5em;
  }

  .blog button {
   margin-bottom: 0em;
  }
  .cardiae-container{
   margin-bottom: -8em;
  }

  .fine{
   padding-bottom: 3em;
  }

  .inst h1 {
   font-size: 3em;
  }

  .inst {
   font-size: 0.7em;
   width: 8em;
  }
  
  .app__chef-contentiz p{
   margin-left: 2em;
   font-size: 0.8em;
   line-height: 0.09em;
  }

  .app__chef-contentiz div{
    padding: 0 0em 0 8em;
  }
}

@media screen and (max-width: 560px) {
  .blog h1{
     width: 100%;
     font-size: 5em;
     padding-bottom: 0.5em;
  }

  .blog p{
   width: 100%;
   padding-bottom: 2.5em;
  }

  .blog button {
   margin-bottom: 0em;
  }
  .cardiae-container{
   margin-bottom: -8em;
  }

  .fine{
   padding-bottom: 3em;
  }

  .inst h1 {
   font-size: 3em;
  }

  .inst {
   font-size: 0.7em;
   width: 8em;
  }
  
  .app__chef-contentiz p{
   margin-left: 2em;
   font-size: 0.8em;
   line-height: 0.09em;
   width: 90%;
  }

  .app__chef-contentiz div{
    padding: 0 10em 0 10em;
  }

  .app__def{
    width: 100%;
    padding: 0 6em 0 2em;
    
  }
}

@media screen and (max-width: 520px) {
  .blog h1{
     width: 100%;
     font-size: 5em;
     padding-bottom: 0.5em;
  }

  .blog p{
   width: 100%;
   padding-bottom: 2.5em;
  }

  .blog button {
   margin-bottom: 0em;
  }
  .cardiae-container{
   margin-bottom: -8em;
  }

  .fine{
   padding-bottom: 3em;
  }

  .inst h1 {
   font-size: 3em;
  }

  .inst {
   font-size: 0.7em;
   width: 8em;
  }
  
  .app__chef-contentiz p{
   margin-left: 2em;
   font-size: 0.8em;
   line-height: 0.09em;
   width: 90%;
  }

  .app__chef-contentiz div{
    padding: 0 0em 0 8em;
    display: flex;
  }

  .app__def{
    width: 90%;
    padding: 0 2em 0 2em;
    
  }

  .section__paddingx{
    padding: 0 1em 0 4em;
    margin: 0em;
    
    
  }

}