.app__header {
    background-color: var(--color-crimson);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-blue);
    letter-spacing: 0.02em;
    line-height: 1.1em;
    font-size: 4.8em;
    margin: 2rem 13rem;
    width: 100%;
    text-align: center;
}

.section__paddingz {
    padding: 2rem 6rem 6rem 6rem;
  }

.p__opensansx {
    font-family: var(--font-alt);
    color: var(--color-blue);
    font-weight: 400;
    letter-spacing: 0.02em;
    text-transform:none;
    line-height: 1.1em;
    font-size: 1.8em;
    text-align: center;
    padding-bottom: 4em;
    margin: 1rem 25rem;
    width: 70%;
  }
  /* style={{ paddingBottom: '3.5rem', margin: '2.5rem 25rem', textAlign: 'center'}} */
/* style={{lineHeight: '1.1em', fontSize: '4.8em', margin: '2rem 13rem', textAlign: 'center'}} */

.app__header-img img {
    width: 100%;
}

.app__wrapper_infoz {
    padding-top: 4.5em;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: top;
    align-items: flex-start;
    flex-direction: column;
  }

  *{
    margin: 0em;
    padding: 0em;
    box-sizing: border-box;
    font-family: sans-serif;
    
  }
  
  
  body .cardie-container{
    background-color: var(--color-crimson);
  }

  .cardie-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2em;
    width: 100%;
  
  }
  
  .cardie {
    width: 100em;
    background-color: var(--color-crimson);
    border-radius: 10px;
    overflow: hidden;
    margin: 4em;
  }
  
  .cardie img {
    width: 100%;
    height: auto;
  }
  
  .cardie-content {
    padding: 3em;
    align-content: center;
  
  }
  
  .cardie-content h3 {
    font-size: 1.8em;
    margin: 1em 2em 1em 2em;
    color: var(--color-blue);
  
  }
  
  .cardi-content p {
    color: var(--color-blue);
    font-size: 1.83em;
    margin: 1em 1em 1em 1.0em;
    line-height: 1.2em;
  }
  
  .cardi-content .btn {
    display: inline-block;
    padding: 1em  2em;
    background-color: #333;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 2em;
    color: white;
  }

  @media screen and (max-width: 1150px) {
       
    .app__header h1 {
        letter-spacing: 0.02em;
        line-height: 1em;
        font-size: 3em;
        width: 95%;
    }  

    .app__header p{
        letter-spacing: 0.006em;
        line-height: 1.2em;
        font-size: 1.8em;
        width: 70%;
    }
}
  @media screen and (max-width: 769px) {
    .app__wrapper_infoz{
      font-size: 0.76em;  
      width: 100%;
      justify-content: center;
    }
    .section__paddi{
      padding: 1em 4em 6em 4em;
    }
}
@media screen and (max-width: 620px) {
  .app__wrapper_infoz{
    font-size: 0.76em;  
    width: 100%;
    justify-content: center;
  }
  .section__paddi{
    padding: 1em 4em 6em 4em;
  }
}

@media screen and (max-width: 520px) {
  .app__wrapper_infoz{
    font-size: 0.76em;  
    width: 100%;
    justify-content: center;
  }
  .section__paddi{
    padding: 1em 2em 6em 2em;
  }
}

@media screen and (max-width: 420px) {
  .app__wrapper_infoz{
    font-size: 0.76em;  
    width: 100%;
    justify-content: center;
  }
  .section__paddi{
    padding: 1em 1em 6em 1em;
  }
}

@media screen and (max-width: 371px) {
  .app__wrapper_infoz{
    font-size: 0.76em;  
    width: 100%;
    justify-content: center;
  }
  .section__paddi{
    padding: 1em 0.5em 6em 0.5em;
  }
}

@media screen and (max-width: 320px) {
  .app__wrapper_infoz{
    font-size: 0.76em;  
    width: 100%;
    justify-content: center;
  }
  .section__paddi{
    padding: 1em 2em 6em 2em;
  }
}





