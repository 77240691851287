*{
    padding: 0; 
    margin: 0; 
    box-sizing: border-box;
}

.app__navbar {
    z-index: 3;
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: var(--color-crimson); */
    
}

.cardal-container {
    position:absolute;
    padding-top: 1em;
    margin-top: 3em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width:100%;
  
  }
  
  .cardal {
    width: 100em;
    /* background-color: var(--color-pantne); */
    border-radius: 10px;
    overflow: hidden;
    /* margin: 1em; */
  }
  
  .cardal img {
    width: 100%;
    height: auto;
  }
  
  .cardal-content {
    padding: 0em;
    align-content: center;
  
  }
  
  .cardal-content h3 {
    font-size: 1.8em;
    margin: 1em 2em 1em 2em;
    color: var(--color-blue);
  
  }
  
  .cardal-content p {
    color: var(--color-blue);
    font-size: 1.83em;
    margin: 1em 1em 1em 1.0em;
    line-height: 1.2em;
  }
  
  .cardal-content .btn {
    display: inline-block;
    padding: 1em  2em;
    background-color: #333;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 2em;
    color: white;
  }


.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.app__navbar-logo img {
    width: 150px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: center;

    list-style: none;
}

.app__navbar-links li {
    margin: 0.7em 1rem;
    cursor: pointer;
}

.app__navbar-links li:hover {
    color: var(--color-grey);
        
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 0px solid var(--color-golden);
    color: var(--color-grey);
}

.app__navbar-login div {
    width: 1px;
    height: 30px;
    background-color: var(--color-gray);
}

.app__navbar-smallscreen {
    display: none;

}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: 0.5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (max-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}


@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
    .app__navbar {
        padding: 1rem;
        max-width: 100vw;
    }
}

@media screen and (max-width: 769px) {
    .app__navbar {
        padding: 1rem;
        max-width: 100vw;
    }
    

    .app__navbar-login {
        display: none;
    }
    .app__navbar-logo img {
        width: 70px;
    }
}
