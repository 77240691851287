.app__gallery {
    display: flex;
    flex-direction: row;
    background: var(--color-greey);
    padding: 4rem 0 0rem 6rem;

}

.app__ga {
    display: flex;
    flex-direction: row;
    background: var(--color-greey);
    padding: 2rem 5rem 1rem 5rem;
    text-align: center;

}

.flexCenter {
    display: flex;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px;
    margin-top: 5px;
  }
  .flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .headTex__cormorante {
    font-family: var(--font-base);
    font-size: 65px;
    flex: content;
    text-align: center;
    color: var(--color-blue);
    background: var(--color-blue);
    line-height: 20px;
    padding: 30px 80px 0px 80px;
  }

  .headTex__cormorant {
    font-family: var(--font-base);
    font-size: 60px;
    flex: content;
    text-align: center;
    color: var(--color-blue);
    background: var(--color-crimson);
    line-height: 10px;
    padding: 45px 80px 1px 80px;
  }

  .p__cormorantez {
    font-family: var(--font-base);
    color: var(--color-blue);
    font-weight: 400;
    text-align: center;
    background: var(--color-crimson) ;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 5.9px;
    font-size: 35px;
    padding-top: 10px;
  }

  .p__opensansed {
    font-family: var(--font-alt);
    color: var(--color-blue);
    background: var(--color-crimson);
    font-weight: 300;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 29px;
    font-size: 19px;
  }
  
  

  .flexColStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .flexColEnd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

.statse {
    width: 90%;
    justify-content: space-between;
}

.stati>:nth-child(1) {
    font-size: 2.5rem;
    color: var(--color-pantone);
}

.flexColCent {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background: var(--color-crimson);
  padding-top: 10px;
  /* border-top: 4px solid var(--color-grey); */
  /* box-shadow: 1px 1px 4px var(--color-gray); */
  /* border-radius: 5%; */
}

.stati>:nth-child(1)>:last-child {
    color: var(--color-grey);
}

.secondaryTexti {
    /* color: rgb(140, 139, 139); */
    font-family: var(--font-alt);
    color: var(--color-gray);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 28px;
    font-size: 66px;
    padding-bottom: 3px;
}

.app__navbar-lin {
  flex: 2;
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  justify-content: flex-start;
  align-items: center;

  list-style: none;
}

.app__navbar-lin li {
  margin: 1rem 1rem;
  cursor: pointer;
}

.app__navbar-lin li:hover {
  color: var(--color-grey);
      
}
