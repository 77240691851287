.app__footer {
    width: 100%;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    background: var(--color-pantone);
    padding-top: 30px;
    
}

.p__opensanse:hover {
    color: var(--color-white);
  }

.p__opensanses {
    font-family: var(--font-alt);
    color: var(--color-dpegray);
    font-weight: 500;
    letter-spacing: 0.08em;
    text-transform:none;
    line-height: 40px;
    font-size: 18px;
  }

.p__opensanse {
    font-family: var(--font-alt);
    color: var(--color-dpgray);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform:none;
    line-height: 40px;
    font-size: 16px;
  }

.app__footer-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;
    padding: 0 2rem;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
    flex: 1;
    margin: 1rem;
    text-align: left;
}

.app__footer-links_logo img:first-child {
    width: 210px;
    margin-bottom: 0.75rem;
}

.app__footer-links_work p:nth-child(2n + 1) {
    margin-bottom: 1rem;
}

.app__footer-links_icons {
    margin-top: 0.5rem;
}

.app__footer-links_icons svg {
    color: var(--color-dpgray);
    margin: 0.5rem;
    font-size: 24px;
    cursor: pointer;
}

.app__footer-links_icons svg:hover {
    color: var(--color-white);

}

.app__footer-headtext:hover {
    color: var(--color-white);
}

.app__footer-headtext {
    font-family: var(--font-base);
    color: var(--color-dpgray);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 32px;
    line-height: 41.6px;
    margin-bottom: 1rem;
}

.footer_copyright {
    margin-top: 3rem;
}

*{
    margin: 0em;
    padding: 0em;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  
  body .cardiac-container{
    background-color: var(--color-pantone);
  }

  .cardiac-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0em;
    width: 100%;
  
  }
  
  .cardiac {
    width: 100em;
    background-color: var(--color-pantone);
    border-radius: 10px;
    overflow: hidden;
    margin: 1em;
  }
  
  .cardiac img {
    width: 100%;
    height: auto;
  }
  
  .cardiac-content {
    padding: 3em;
    align-content: center;
  
  }
  
  .cardiac-content h3 {
    font-size: 1.8em;
    margin: 1em 2em 1em 2em;
    color: var(--color-blue);
  
  }
  
  .cardiac-content p {
    color: var(--color-blue);
    font-size: 1.83em;
    margin: 1em 1em 1em 1.0em;
    line-height: 1.2em;
  }
  
  .cardiac-content .btn {
    display: inline-block;
    padding: 1em  2em;
    background-color: #333;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 2em;
    color: white;
  }

@media screen and (min-width: 2000px) {
.app__footer-headtext {
    font-size: 51px;
    line-height: 61px;
}
}

@media screen and (max-width: 769px) {
   .app__footer-links {
    align-items: center;
    flex-direction: column;
    padding: 0;

   } 

   .app__footer-links_contact,
   .app__footer-links_logo,
   .app__footer-links_work {
     margin: 2rem 0;
     width: 50%;
    }
}

@media screen and (max-width: 650px) {
   .app__footer {
    padding: 0 0 2rem 0;
   } 
}

@media screen and (max-width: 350px) {
    .app__footer-links_logo img:first-child {
        width: 50%;
    }   
}